body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    background-color: #0072C6;
    font-size: 16px;
}

a {
	color: #B2AA9A;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}
a,
a:hover,
a:active {
	outline: none !important
}
a:hover,
a:active {
	color: #0072C6;
}
.logout{
    font-weight: 700;
    cursor: pointer;
    color: #0072C6;
}
.page{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin: auto;
}
.header {
    width: 100%;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.15);
}
.header-container {
    width: calc(100% - 80px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-action{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_link{
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_link div{
    margin-left: 4px;
}
.header_link svg{
    margin-top: -2px;
}
.header_text {
	color: #B2AA9A;
	font-size: 16px;
    margin-right: 14px;
}
.header_text strong {
    color: #0072C6;
	font-weight: 700;
}
.logo{
    margin-left: 20px;
    width: 220px;
}
.content{
    width: 100%;
}
.bg-cover {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.bg-cover-b {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}
.bg-contain {
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
}
.bg-repeat {
    width: 100%;
    background-repeat: repeat-x;
    background-size: contain;
    background-position: bottom;
}
.image{
    width: 100%;
}
.video{
    width: 100%;
}
.image2{
    width: 70%;
}
.title_image{
    width: 50%;
}
.title_image2{
    width: 50%;
}
.full-video {
    width: 100%;
}
.full {
    width: 100%;
}
.full2 {
    width: 80%;
}
.cols {
    width: 50%;
}
.cols .cols-img{
    width: 90%;
}
.cols3 {
    width: 24%;
    margin-left: 10%;
    margin-right: 10%;
}
.depoiments{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items:flex-start;
    justify-content: space-evenly;
}
.btns{
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
}
.btn-next,
.btn-prev {
	position: absolute;
	display: inline-block;
	z-index: 999;
	color: #fff;
	top: 50%;
	transform: translate(0, -50%);
	background: rgba(0, 0, 0, 0);
	width: 70px;
	height: 70px;
	overflow: hidden;
	text-align: center
}
.btn-next {
	right: 2px
}
.btn-prev {
	left: 2px;
}
.btn-prev,
.btn-next{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.flex-row {
    width: 80%;
    display: flex;
    flex-direction: row;
}
.flex-row-form{
    width: 400px;
    display: flex;
    flex-direction: row;
}
.flex-row-form .cols {
    width: 33.33%;
}
.form-email{
    width: 100%;
    position: relative;
}
.form-email input{
    width: calc(100% - 18px);
}
.d-email{
    color: #000929;
    position: absolute;
    top: 6px;
    right: 10px;
    font-weight: 700;
}
.flex-row-b {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.flex-row-c {
    width: 90%;
    display: flex;
    flex-direction: row;
}
.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.check{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 700;
    color: #fff;
    opacity: 0.8;
    cursor: pointer;
}
.check:hover{
    opacity: 1;
}
.check div{
    margin-left: 4px;
}
.checked{
    color: #ffc411;
    opacity: 1 !important;
}
.justify-end {
    justify-content: flex-end;
}
.footer {
    width: 100%;
    color: #fff;
    background-color: #0072C6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
}
.footer-container{
    width: calc(100% - 80px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer-left {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}
.footer-right {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.footer-icons{
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-icons a{
    margin-left: 20px;
    pointer-events: none;
}
.dev_link{
    margin-left: 8px;
    height: 30px;
    opacity: 0.7;
    transition: all 0.2s;
}
.dev_link:hover{
    opacity: 1;
}
.dev_link img{
    height: 30px;
}
.container{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}
.container-video-comments{
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.embed-container {
    background-color: #000;
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
    width: 100%;
    margin-right: 1px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.container-video{
    width: 75%;
}
.container-comments{
    width: 25%;
    height: 0px;
    overflow: hidden;
    position: relative;
}
.container-comments-wrapper{
    width: calc(100% - 30px);
    height: 100%;
    padding: 0 15px 120px 15px;
    overflow: scroll;
    position: relative;
}
.title{
    font-size: 18px;
    margin: 20px 0 10px 0;
    color: #0072C6;
}
.divider-comment{
    padding: 10px 0 0 0;
    margin: 10px 0 0 0;
    border-top: 1px solid #EDECE3;
}
.divider-comment:last-child {
    margin-bottom: 100px;
}
.d-input{
    width: 320px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}
.d-input.big{
    width: 420px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.d-input textarea{
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    border: 2px solid #B2AA9A;
    border: 0;
    outline: 0;
    padding: 8px;
    width: 100%;
}
.d-input input{
    background-color: #fff;
    color: #000;
    border-radius: 6px;
    border: 2px solid #B2AA9A;
    outline: 0;
    padding: 8px;
    width: calc(100% - 20px);
    height: 20px;
}
.d-select {
    position: absolute;
    right: 6px;
    top: 26px;
}
.d-select select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 14px 10px;
    color: #000;
    border-radius: 0;
    border: 0;
    outline: none;
    outline: 0;
    padding: 8px 18px 8px 8px;
    width: 100%;
    height: 38px;
}
select::-ms-expand {
    display: none;
}

.d-input textarea:focus,
.d-input input:focus{
    border-color: #0072C6;
}

label{
    color: #0072C6;
    font-weight: 700;
    margin-bottom: 6px;
}

.form-container{
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
}
.form-container-wrapper{
    padding: 10px 15px 10px 15px;
    margin-top: -24px;
    background-color: #fff;
    border-top: 1px solid #EDECE3;
}
.l-input{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.l-input textarea{
    width: calc(100% - 16px);
    background-color: #fff;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    border-radius: 6px;
    border: 0;
    outline: 0;
    padding: 8px;
    font-family: 'Lato', sans-serif;
    resize: none;
}
.l-input textarea:focus{
    background-color: #fff;
    color: #000;
}

.title_g{
    color: #009E49;
    font-weight: 700;
    font-size: 18px;
}
.title_b{
    color: #0072C6;
    font-weight: 700;
    font-size: 18px;
}
.btn-send{
    color: #fff;
    background-color: #0072C6;
    font-weight: 700;
    outline: 0;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 10px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
}
.btn-send-invert{
    color: #fff;
    background-color: #009E49;
    font-weight: 700;
    outline: 0;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    border-radius: 10px;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.2s;
}
.btn-send:hover,
.btn-send:focus,
.btn-send:active,
.btn-send-invert:hover,
.btn-send-invert:focus,
.btn-send-invert:active{
    color: #fff;
    opacity: 0.8;
}

.comment-name{
    width: 100%;
    color: #009E49;
    font-weight: 700;
    margin-bottom: 6px;
}
.comment-text{
    width: 100%;
    font-size: 16px;
    color: #000;
    font-weight: 400;
}

.no-comment{
    color: #000;
    font-weight: 400;
}

.modal-comments{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal-comments-wrapper{
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    width: 300px;
    padding: 35px 35px 0px 35px;
    display: flex;
    flex-direction: column;
}
.close{
    position: absolute;
    right: 4px;
    top: 4px;
    color: #999;
    cursor: pointer;
}
.close:hover{
    color: #000;
}

.disabled{
    pointer-events: none;
    opacity: 0.5;
}

.loading{
    pointer-events: none;
    opacity: 0.8;
}
.btn{
    background-color: #0072C6;
    padding: 14px 46px;
    cursor: pointer;
    display: inline-block;
    border-radius: 10px;
}
.btn:hover{
    opacity: 0.8;
}
.btn img{
    width: 180px;
}

.error{
    color: #fff;
    font-weight: 700;
    background-color: #d70b0b;
    padding: 10px 16px 12px 16px;
    border-radius: 10px;
    display: inline-block;
}
.success{
    color: #fff;
    font-weight: 700;
    background-color: #3fa706;
    padding: 10px 16px 12px 16px;
    border-radius: 10px;
    display: inline-block;
}
.success.space,
.error.space{
    margin-left: 10px;
    margin-right: 10px;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}
.mt-10{
    margin-top: 10px;
}
.mt-20{
    margin-top: 20px;
}
.mt-30{
    margin-top: 30px;
}
.mt-40{
    margin-top: 40px;
}
.mt-50{
    margin-top: 50px;
}
.mt-60{
    margin-top: 60px;
}
.mt-70{
    margin-top: 70px;
}
.mt-80{
    margin-top: 80px;
}
.mt-90{
    margin-top: 90px;
}
.mt-100{
    margin-top: 100px;
}
.mt-big{
    margin-top: 150px;
}

.mb-10{
    margin-bottom: 10px;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-30{
    margin-bottom: 30px;
}
.mb-40{
    margin-bottom: 40px;
}
.mb-50{
    margin-bottom: 50px;
}
.mb-60{
    margin-bottom: 60px;
}
.mb-70{
    margin-bottom: 70px;
}
.mb-80{
    margin-bottom: 80px;
}
.mb-90{
    margin-bottom: 90px;
}
.mb-100{
    margin-bottom: 100px;
}
.mb-big{
    margin-bottom: 150px;
}
.mobile-hidden {
    display: block !important;
}
.mobile-hidden-f {
    display: flex !important;
}
.mobile-view,
.mobile-view-d {
    display: none !important;
}

/*MOBILE*/
@media (max-width: 440px){
    .mobile-hidden,
    .mobile-hidden-f {
        display: none !important;
    }
    .mobile-view {
        display: block !important;
    }
    .mobile-view-d {
        display: flex !important;
    }
    .mt-100{
        margin-top: 20px;
    }
    .mb-100{
        margin-bottom: 20px;
    }
    .mt-big{
        margin-top: 60px;
    }
    .mb-big{
        margin-bottom: 60px;
    }
    .mt-50{
        margin-top: 20px;
    }
    .mb-50{
        margin-bottom: 20px;
    }
    .mb-60 {
        margin-bottom: 20px;
    }
    .mt-60 {
        margin-top: 20px;
    }
    .mt-40{
        margin-top: 10px;
    }
    .mb-40{
        margin-bottom: 10px;
    }
    .full2 {
        width: 90%;
    }
    .cols3 {
        width: 33%;
        margin-left: 4%;
        margin-right: 4%;
    }
    .flex-row {
        width: 90%;
    }
    .flex-row-b {
        width: 90%;
    }
    .flex-row-c {
        width: 90%;
    }
    .container {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .cols .cols-img {
        width: 90%;
    }
    .image2 {
        width: 70%;
    }
    .btn{
        background-color: #0072C6;
        padding: 8px 32px 6px 32px;
        cursor: pointer;
        display: inline-block;
        border-radius: 10px;
    }
    .btn img{
        width: 100px;
    }
    .btn-next, .btn-prev {
        width: 40px;
        height: 40px;
    }
    .title_image {
        width: 80%;
    }
    .flex-row-form {
        width: 350px;
    }
    .check {
        font-size: 12px;
    }

    .d-input.big{
        width: 360px;
    }

    .container-video-comments {
        width: 100%;
        flex-direction: column;
    }
    .container-video {
        width: 100%;
    }
    .container-comments {
        width: 100%;
        height: 50vh !important;
    }

    .header {
        padding: 30px 0;
    }
    .header-container {
        flex-direction: column;
        width: calc(100% - 60px);
    }
    .header-action{
        margin-top: 10px;
    }
    .logo{
        margin-left: 0px;
        width: 180px;
    }

    .footer {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .footer-container{
        width: calc(100% - 60px);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .footer-left{
        margin-bottom: 20px;
    }

}